* {
  background-color: #313951;
  font-family: 'Comic Neue', cursive;
  color: #ffffff;
}

/* body {
  align-self: center;
} */

.canvas-container {
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
}

@media only screen and (max-width: 1000px) {
  .row2 {
    display: none;
  }
  .spacer{
    display: none;
  }
  .row3 {
    font-size: 15px !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  /* background-color: green; */
  /* margin-top: 5%; */
  /* align-content: center; */

  /* justify-self: center; */
}

.flexbox {
  margin: 1%;
  /* border: 3px solid #ffffff;
  background-color: blue; */
}

.row1 {  
  font-weight: bold;
/*   margin-top:-20px; */
  margin-bottom:0px;
  text-align: center;
  letter-spacing: 4px;
  font-size: 20px;
  /* border-color: #ffffff;
  border-style: solid; */
}


.row2 {
/*   margin-top:-20px; */
  /* min-height: auto;
  width: 100%;
  flex-shrink: 0;
  align-self: center;
  display: flex; */
  text-align: center;
  border-color: #ffffff;
  border-style: solid;
}

.row3 {
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  /* border-color: #ffffff;
  border-style: solid; */
}

a {
  text-decoration: none;
}
.bottom-container {
  display:flex;
  flex-direction: row;
  align-self: stretch;
 justify-content: space-evenly;
 flex-wrap: wrap;
 
/*   align-content: stretch;
  justify-items:center;  */
}
/* .info-container {
  display: flex;
  flex-direction: row;
  justify-items: space-evenly !important;
  flex-wrap: wrap;
  align-items: center ;
  justify-content: space-evenly;
  align-content: center;
  
} */
.stretcher {
  display:flex;
  align-self: stretch;
}
.lamp{
  margin: 10px;
}
.info {
  display: flex;
  flex-direction: column;
 /*  justify-content: center; */
 /*  align-items:center; */
  margin-top: 40px;
}

.inline {
   display: flex;
   align-items: center;
   justify-content:flex-start;

  text-align:center ;
}



.icons{
  margin-right: 10px;
  margin-bottom: 20px;
}

.name{
  display:flex;
  flex-direction: column;
}

@media only screen and (max-width: 578px) {
  .inline {
    margin: 0px !important;
  }
}
